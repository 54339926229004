<template>
  <div class="flex-container scrollable pr-3">
    <page-title value="Régularisations"/>
    <Card class="mb-3">
      <template #content>
        <div class="flex justify-content-between">
          <div>
            <div class="mb-2">
              <label for="dropdownIndep">Porté</label>
              <div class="mt-2">
                <Dropdown style="width: 250px;" id="dropdownIndep" :filter="true"
                          :filter-fields="['first_name', 'last_name']"
                          :option-label="(val) => val.last_name.toUpperCase() + ' ' + val.first_name"
                          v-model="newIndependent" :options="independents"></Dropdown>
              </div>
            </div>
            <div v-if="newIndependent" class="mb-2">
              <label for="date">Date</label>
              <div class="mt-2">
                <Calendar style="width: 250px;" id="date" :showIcon="true" v-model="newDate"
                          date-format="dd/mm/yy"></Calendar>
              </div>
            </div>
            <div v-if="newDate && newIndependent" class="mb-2">
              <label for="value">Montant</label>
              <div class="flex flex-row gap-2 align-items-center mt-2">
                <SelectButton v-model="signValue" option-value="value"
                              :options="[{ icon: 'pi pi-plus', value: 'positive' }, { icon: 'pi pi-minus', value: 'negative' }]">
                  <template #option="slotProps">
                    <i :class="slotProps.option.icon"/>
                  </template>
                </SelectButton>
                <InputNumber id="value" v-model="newValue" :min="0" style="width: 250px;" locale="fr-FR"
                             currency="EUR" mode="currency" highlightOnFocus/>
              </div>
            </div>
            <div v-if="newValue != undefined && newDate && newIndependent" class="mb-2">
              <label for="label">Motif</label>
              <div class="mt-2">
                <Textarea :rows="3" style="width: 250px;" id="label" v-model="newLabel"/>
              </div>
            </div>
            <Button v-if="newValue != undefined && newValue !== 0 && newDate && newIndependent && newLabel"
                    @click="createRegularisation" label="Valider" icon="pi pi-save"></Button>
          </div>
          <div>
            <Button label="Importer un fichier" icon="pi pi-plus" @click="toggleFileUpload"></Button>
            <FileUpload ref="upload" style="display: none" mode="basic" auto custom-upload @uploader="uploadCSV"
                        accept="text/csv,application/vnd.ms-excel" :maxFileSize="100000000"/>
          </div>
        </div>
      </template>
    </Card>
    <DataTable ref="dt" :value="regularisations" :paginator="true" class="p-datatable-customers"
               :rows="constants.defaultRowNumber"
               dataKey="id" :rowHover="true" v-model:filters="filters" filterDisplay="menu"
               v-model:selection="selectedRegularisations" data-key="id"
               paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
               :rowsPerPageOptions="constants.rowsPerPageOptions"
               :currentPageReportTemplate="$t('datatable.currentPageReportTemplate', {first: '{first}', last: '{last}', totalRecords: '{totalRecords}', target: $t('datatable.target.data')})"
               scrollable scroll-height="400px">
      <template #header>
        <div class="p-d-flex flex justify-content-between ml-2">
        <span class="p-input-icon-left">
          <i class="pi pi-search"/>
          <InputText v-model="filters['global'].value" :placeholder="$t('search')"/>
        </span>
        </div>
      </template>
      <template #empty>
        {{ $t('datatable.notFound', {target: $t('datatable.target.data')}) }}
      </template>
      <template #loading>
        {{ $t('datatable.load', {target: $t('datatable.target.data')}) }}
      </template>
      <Column selectionMode="multiple" headerStyle="width: 3rem"></Column>
      <Column field="independent.last_name" :header="$t('lastname').toUpperCase()" sortable>
      </Column>
      <Column field="independent.first_name" :header="$t('firstname').toUpperCase()" sortable>
      </Column>
      <Column field="value" header="MONTANT" sortable>
        <template #body="data">
          <span>{{ getEuroFormat(data.data.value) }}</span>
        </template>
      </Column>
      <Column field="label" header="MOTIF" sortable>
      </Column>
      <Column field="date" :header="$t('date').toUpperCase()" sortable>
        <template #body="{data}">
          <span> {{ new Date(data.date).toLocaleDateString() }}</span>
        </template>
      </Column>
    </DataTable>
    <div class="flex justify-content-end mt-3">
      <Button class="p-button-danger" v-if="selectedRegularisations.length" type="button" @click="deleteRegularisations">
        Supprimer les régularisations
      </Button>
    </div>
  </div>
</template>

<script>
import {mapGetters, mapState} from "vuex";
import {FilterMatchMode} from "primevue/api";
import {getEuroFormat} from "@/utils/Money";
import PageTitle from "@/components/PageTitle";
import {csvToJson} from "@/utils/CsvLoader";

export default {
  name: "Regularisations",
  components: {PageTitle},
  data() {
    return {
      newLabel: '',
      newIndependent: null,
      newValue: 0,
      newDate: new Date(),
      getEuroFormat: getEuroFormat,
      selectedRegularisations: [],
      signValue: 'positive',
      filters: {
        'global': {value: null, matchMode: null},
        'independent.first_name': {value: null, matchMode: FilterMatchMode.IN},
        'independent.last_name': {value: null, matchMode: FilterMatchMode.IN},
        'label': {value: null, matchMode: FilterMatchMode.IN},
      },
    }
  },
  computed: {
    ...mapState({
      constants: state => state.constants,
    }),
    ...mapGetters({
      independents: 'independent/getIndependents',
      regularisations: 'regularisation/getRegularisations',
    })
  },
  async created() {
    this.$store.state.misc.loading = true
    await this.$store.dispatch('regularisation/getAllRegularisations')
    await this.$store.dispatch('independent/getAllIndependents')
    this.$store.state.misc.loading = false
  },
  methods: {
    uploadCSV(csv) {
      this.$store.state.misc.loading = true
      csvToJson(csv, async (jsonResult) => {
        this.csvResultData = jsonResult
        await this.$store.dispatch('regularisation/importCsv', jsonResult)
        await this.$store.dispatch('regularisation/getAllRegularisations')
        this.$store.state.misc.loading = false
      })
    },
    toggleFileUpload() {
      this.$refs.upload.choose()
    },
    async createRegularisation() {
      this.$store.state.misc.loading = true
      let value = 0
      if (this.signValue === 'positive') {
        value = this.newValue
      } else if (this.signValue === 'negative') {
        value = -this.newValue
      }
      const data = {
        independent: this.newIndependent.id,
        date: this.newDate,
        value: value,
        label: this.newLabel
      }
      await this.$store.dispatch('regularisation/createRegularisation', data)
      await this.$store.dispatch('regularisation/getAllRegularisations')
      this.newValue = 0
      this.newLabel = ''
      this.$store.state.misc.loading = false
    },
    async deleteRegularisations () {
      this.$confirm.require({
        header: 'Supprimer les régularisations',
        message: 'Vous êtes sur le point de supprimer les régularisations sélectionnées',
        acceptLabel: 'Confirmer',
        rejectLabel: 'Annuler',
        accept: async () => {
          this.$store.state.misc.loading = true
          for (const regularisation of this.selectedRegularisations) {
            await this.$store.dispatch('regularisation/deleteRegularisation', regularisation)
          }
          this.$toast.add({severity:'success', summary:'Succès', detail:'Régularisations supprimées', life: 3000});
          this.selectedRegularisations = []
          await this.$store.dispatch('regularisation/getAllRegularisations')
          this.$store.state.misc.loading = false
        },
      })
    }
  }
}
</script>

<style scoped>

</style>
