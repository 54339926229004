<template>
  <div class="mb-3 text-primary font-bold text-md lg:text-2xl">{{ value }}</div>
</template>

<script>
export default {
  name: "PageTitle",
  props: {
    value: {
      type: String,
      required: true
    }
  }
}
</script>

<style scoped>

</style>
