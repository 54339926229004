/* -- USAGE --
  html...
  <FileUpload mode="basic" choose-label="Importer recto" accept=".csv" auto custom-upload @uploader="onCsvUpload" class="p-button-rounded k-button-upload"/>

  js...
  methods: {
    onCsvUpload(csv) {
      csvToJson(csv, (jsonResult) => {
        console.log('jsonResult')
        console.log(jsonResult)
        **DoWhateverYouWant**
      })
    },
  }
 */
const defaultRowDelimiter = /;(?=(?:[^"\n]*"[^"\n]*")*[^"\n]*$)/g
// const regexReplacer = /"\\"|\\""/g
const regexReplacer = '"'

/**
 *
 * @param str the string returned by the FileReader
 * @param delimiter delimiter if a specific delimiter is needed, by default ','
 * @return {*} return the json array of objects parsed
 */
export function csvParser (str, delimiter = defaultRowDelimiter) {
    const splitter = '\r\n' || '\r' || '\n' // prevent from having a \x somewhere
    // slice from start of text to the first \n index
    // use split to create an array from string by delimiter
    const headers = str
        .toLowerCase()
        .replaceAll(' ', '_')
        .slice(0, str.indexOf(splitter))
        .split(delimiter);

    // slice from \n index + 1 to the end of the text
    // use split to create an array of each csv value row
    const rows = str.slice(str.indexOf('\n') + 1).split(splitter);

    // Map the rows
    // split values from each row into an array
    // use headers.reduce to create an object
    // object properties derived from headers:values
    // the object passed as an element of the array
    // return the array
    return rows.map(function (row) {
        const values = row.split(delimiter);
        return headers.reduce(function (object, header, index) {
            object[header] = values[index]?.replaceAll(regexReplacer, '');
            return object;
        }, {});
    });
}

/**
 *
 * @param fileObj the object returned by the uploadFunction
 * @param callback return a param contains the json result of the csv reading
 * @param delimiter if a specific delimiter is needed, by default ','
 */
export function csvToJson (fileObj, callback, delimiter = defaultRowDelimiter) {
    const csvInput = fileObj.files[0]
    const reader = new FileReader();

    reader.onload = (e) => {
        const text = e.target.result;
        const jsonResult = csvParser(text, delimiter)
        callback(jsonResult)
    }

    try {
        reader.readAsText(csvInput)
    } catch (error) {
        console.log('error')
        console.log(error)
    }
}
